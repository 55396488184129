var sumo = require('sumoselect');
var slick = require('slick-carousel');
var select2 = require('select2');
var scrollbar = require('malihu-custom-scrollbar-plugin');

import imagesLoaded from 'imagesloaded/imagesloaded.pkgd.min';

if ($('body').hasClass('en')) {
    var placeholder = 'Enter one or more locations';
    var sumoSelectSelected = 'Selected';
} else if ($('body').hasClass('de')) {
    var placeholder = 'Eine(n) oder mehrere Städte / Standorte eingeben';
    var sumoSelectSelected = 'Ausgewählt';
} else if ($('body').hasClass('it')) {
    var placeholder = 'Inserisci la città o posto';
    var sumoSelectSelected = 'Selezionato';
} else if ($('body').hasClass('ru')) {
    var placeholder = 'Введите одно или несколько местоположений';
    var sumoSelectSelected = 'Выбрано';
} else if ($('body').hasClass('si')) {
    var placeholder = 'Vpišite in izberite eno ali več lokacije';
    var sumoSelectSelected = 'Označeno';
} else if ($('body').hasClass('al')) {
    var placeholder = 'Shënoni një apo më shumë lokacione';
    var sumoSelectSelected = 'Zgjedhur';
} else if ($('body').hasClass('sk')) {
    var placeholder = 'Zadajte a vyberte jedno alebo viac miest';
    var sumoSelectSelected = 'Zgjedhur';
} else if ($('body').hasClass('cz')) {
    var placeholder = 'Zadejte a vyberte jedno nebo více měst';
    var sumoSelectSelected = 'Zgjedhur';
} else {
    var placeholder = 'Upišite i odaberite jednu ili više lokacija';
    var sumoSelectSelected = 'Označeno';
}

var SumoSelectOptions = {
    captionFormat: sumoSelectSelected + ': {0} ',
    captionFormatAllSelected: sumoSelectSelected + ': {0}'
};

function searchInit() {
    $('select.search-select').SumoSelect(SumoSelectOptions);
    $('select.inquiry-select').SumoSelect(SumoSelectOptions);

    $('select.action-select').SumoSelect(SumoSelectOptions);
    $('select.type-select').SumoSelect(SumoSelectOptions);
    $('select.type-select-hidden').SumoSelect(SumoSelectOptions);
    $('select.subtype-select').SumoSelect(SumoSelectOptions);
    $('select.sort-by-select').SumoSelect(SumoSelectOptions);
    $('select.options-select').SumoSelect(SumoSelectOptions);

    $('select.price-select').SumoSelect(SumoSelectOptions);
    $('select.rooms-select').SumoSelect(SumoSelectOptions);
    $('select.area-select').SumoSelect(SumoSelectOptions);
    $('select.range_seaDistance').SumoSelect(SumoSelectOptions);

    // CUSTOM AUTOCOMPLETE
    $('.autocomplete-search').select2({
        dropdownParent: $('body'),
        placeholder: placeholder,
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
    });

    if ($('.search-selects').length > 0) {
        $('.search-selects .select-column select').each(function (e) {
            $(this).SumoSelect(SumoSelectOptions);
        });
    }

    var allCities = false;
    var selectedRegions = [];
    $('.sumo_customLocation li').on('click', function () {
        if (!allCities) {
            allCities = getAllCities();
        }

        var label = $(this).find('label').text();
        if ($(this).hasClass('selected')) {
            selectedRegions.push(label);
        } else {
            selectedRegions = jQuery.grep(selectedRegions, function (value) {
                return value != label;
            });
        }

        markSelected(selectedRegions, allCities);
    });

    function markSelected(selectedRegions, allCities) {
        var cities = [];
        selectedRegions.forEach(function (region) {
            cities = cities.concat(allCities[region]);
        });

        $('.sumo_cityArea ul.options li').each(function () {
            if (cities.includes($(this).find('label').text())) {
                if (!$(this).hasClass('selected')) {
                    $(this).trigger('click');
                }

                $(this).show();
            } else {
                if ($(this).hasClass('selected')) {
                    $(this).trigger('click');
                }

                $(this).hide();
            }
        });
    }

    function getAllCities() {
        var all = [];
        $('#customLocation option').each(function (i, city) {
            all[city.text] = city.value.split(',');
        });

        return all;
    }

    window.mobileCheck = function () {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    function manipulateAutocompleteInput(selectElement, select2Container) {
        let currentCount = $(select2Container).find('.select2-selection__choice').length;

        if (currentCount > 0) {
            $(select2Container).css('height', 'auto');
        }

        $(selectElement).on('select2:select', function (e) {
            if (mobileCheck()) {
                currentCount++;
                if (currentCount > 0) {
                    $(select2Container).css('height', 'auto');
                } else {
                    $(select2Container).css('height', '');
                }
            }
        });

        $(selectElement).on('select2:unselect', function (e) {
            if (mobileCheck()) {
                currentCount--;
                if (currentCount > 0) {
                    $(select2Container).css('height', 'auto');
                } else {
                    $(select2Container).css('height', '');
                }
            }
        });

        $(selectElement).on('select2:clear', function (e) {
            if (mobileCheck()) {
                currentCount = 0;
                if (currentCount > 0) {
                    $(select2Container).css('height', 'auto');
                } else {
                    $(select2Container).css('height', '');
                }
            }
        });
    }

    $('.autocomplete-detail-search').select2({
        dropdownParent: $('#detail-search'),
        placeholder: placeholder,
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
    });

    if (!mobileCheck()) {
        $('.homepage-search .select2-container').mCustomScrollbar({
            axis: 'x',
            theme: 'dark-thin',
            autoExpandScrollbar: true,
            advanced: {
                autoExpandHorizontalScroll: true,
                updateOnContentResize: true
            }
        });

        $('.autocomplete-search').on('select2:select', function (e) {
            setTimeout(function () {
                $('.homepage-search .select2-search.select2-search--inline input').first().focus();
            }, 50);
        });

        $('.group-search .search-box .select2-container').mCustomScrollbar({
            axis: 'x',
            theme: 'dark-thin',
            autoExpandScrollbar: true,
            advanced: {
                autoExpandHorizontalScroll: true,
                updateOnContentResize: true
            }
        });

        $('.modal-body .search-box .select2-container').mCustomScrollbar({
            axis: 'x',
            theme: 'dark-thin',
            autoExpandScrollbar: true,
            advanced: {
                autoExpandHorizontalScroll: true,
                updateOnContentResize: true
            }
        });
    } else {
        manipulateAutocompleteInput('.group-search .mobile-search-wrapper .autocomplete-search', '.mobile-search-wrapper .select2-container--default');
        manipulateAutocompleteInput('#detail-search .search-box .autocomplete-detail-search', '#detail-search .select2-container--default');
    }

    $('.range-col > p').on('click', function () {
        $('.range-wrapper').removeClass('active');

        if ($(this).parent().hasClass('active')) {
            $('.range-col').removeClass('active');
        } else {
            $('.range-col').removeClass('active');
            $(this).parent().addClass('active');
            $(this).parent().find('.range-wrapper').addClass('active');
        }
    });

    $('.group-search .sumo_subType').on('click', function () {
        $('.group-search .range-col').removeClass('active');
        $('.group-search .range-wrapper').removeClass('active');
    });

    $('html').click(function () {
        $('.property-type .SumoSelect').removeClass('visible').removeClass('open');
        $('.property-type .optWrapper').removeClass('d-block');
    });

    $('.search-options').on('click', '> li', function (e) {
        // e.preventDefault();
        if ($(this).hasClass('action-item-wrapper')) {
            $('.search-options > li').removeClass('active');
        } else {
            $('.search-options > li').removeClass('active');
            $(this).addClass('active');
        }
    });

    $('.property-type').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('.property-type').parents('li').removeClass('active');
        $('.property-type').parent('.trigger').addClass('active');

        $('.property-type .SumoSelect').toggleClass('visible').toggleClass('open');
        $('.property-type .optWrapper').toggleClass('d-block');
    });
}

$(function () {
    $(document).on('click', '.trigger', function (e) {
        e.preventDefault();
        $('.input-wrapper > span, .input-wrapper > input').addClass('hidden').css('display', 'none');
        $('.' + $(this).data('type')).removeClass('hidden').css('display', '');

        if ($(this).data('type') === 'autocomplete-search') {
            $('.input-wrapper > span').css('display', '');
        }
    });

    $(document).on('click', '.action-item-wrapper label', function (e) {
        var inputSearch = document.querySelector('#' + this.getAttribute('for'));
        var isInputChecked = inputSearch.hasAttribute('checked');

        if (isInputChecked) {
            inputSearch.removeAttribute('checked');
        } else {
            inputSearch.setAttribute('checked', 'checked');
        }
    });

    if (!localStorage.getItem('_dmn_count') > 0 && $('.property-back').length > 0) {
        $('.property-back').addClass('disabled');
    }

    $(document).on('click', '.btn-search-trigger', function (e) {
        // $(this).parents().find('form .input-group-append button').trigger('click');
        $(this).parents().find('form').trigger('submit');
    });

    $(document).on('click', '.property-back', function (e) {
        e.preventDefault();

        if (localStorage.getItem('_dmn_count') > 0) {
            localStorage.setItem('_dmn_back', 1);
            window.history.back();
        }
    });

    var pageLocale = $('html').attr('lang');

    // UČITAVANJE NEKRETNINA NA SKUPNOM PRIKAZU
    if ($('#property-group-view .group-search').length > 0) {
        if (localStorage.getItem('_dmn_back') == '1') {
            var url = $('.group-property-section').attr('data-path') + '?' + $('.detail-search form').serialize() + '&order=' + $('.sort-by-select').val() + '&limit=' + localStorage.getItem('_dmn_count');
        } else {
            var url = $('.group-property-section').attr('data-path') + '?' + $('.detail-search form').serialize() + '&order=' + $('.sort-by-select').val();
        }

        var query = window.location.search;
        if (query) {
            $('.save-search').fadeIn();
        }

        $.ajax({
            url: url,
            success: function (r, status, jqXHR) {
                $('.preloader').hide();
                $('.property-wrapper').append(r);
                settingCardImagesDimensions();

                if (realestate_total_count !== false && $('.property-wrapper .property-card').length >= realestate_total_count) {
                    $('#load-more').hide();
                }

                if (localStorage.getItem('_dmn_back') == '1') {
                    setTimeout(function () {
                        $(document).scrollTop(localStorage.getItem('_dmn_scroll'));
                        localStorage.removeItem('_dmn_scroll');
                        localStorage.removeItem('_dmn_back');
                        localStorage.removeItem('_dmn_count');
                    }, 1000);
                } else {
                    $(document).scrollTop($(window).scrollTop());
                }

                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);

                window.dispatchEvent(new CustomEvent('propertiesLoadedEvent'));
            },
            error: function (err) {
                $('.preloader').hide();
                $('#property-group-view .no-result-message').removeClass('d-none');
            }
        });
    }

    $(document).delegate('.property-card a', 'click', function (e) {
        e.preventDefault();
        localStorage.setItem('_dmn_count', $('.property-group-view .property-card').length == 0 ? 12 : $('.property-group-view .property-card').length);
        localStorage.setItem('_dmn_scroll', $(window).scrollTop());
        window.location = this.href;
    });

    function settingCardImagesDimensions() {
        var img = $('.card.property-card .card-heading img');

        img.on('load', function () {
            var imgWidth = $(this).width();
            var imgRatio = 1.5022;
            var imgHeight = imgWidth / imgRatio;

            $(this).height(imgHeight);
        });
    }

    // UČITAVANJE SLIČNIH NEKRETNINA (DETALJNI PRIKAZ)
    if ($('#property .similar-property-wrapper').length > 0) {
        $.ajax({
            url: $('.similar-property-wrapper').attr('data-path') + '/html?limit=3',
            success: function (r, status, jqXHR) {
                if ($.isEmptyObject(r)) {
                    return $('.similar-property-section').remove();
                }

                $('.preloader-similar').hide();
                $('.similar-property-wrapper').html(r);
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });
    }

    // UČITAVANJE PROJEKATA NA SKUPNOM PRIKAZU
    if ($('#project-group-view').length > 0) {
        $.ajax({
            url: '/project/ajax/html/' + pageLocale + '?order=' + $('.sort-by-select').val(),
            success: function (r, status, jqXHR) {
                $('.preloader').hide();
                $('.project-wrapper').append(r);
                settingCardImagesDimensions();

                $(document).scrollTop($(window).scrollTop());
                getProjectsCount();

                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });
    }

    // UČITAVANJE NEKRETNINA PROJEKTA (DETALJNI PRIKAZ)
    var project_properties_total_count = false;
    if ($('#project .project-property-wrapper').length > 0) {
        $.ajax({
            url: $('.project-property-wrapper').attr('data-path') + '?limit=6&project=' + $('.project-property-wrapper').data('project-id'),
            success: function (r, status, jqXHR) {
                $('.preloader-project-properties').hide();
                $('.project-property-wrapper').html(r);
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });

        var form_params = $('.project-properties form').serialize();
        if (project_properties_total_count == false) {
            $('#load-more-project').on('click', function (e) {
                e.preventDefault();
                $.ajax({
                    url: propertyCountRoute + '?' + form_params,
                    success: function (r, status, jqXHR) {
                        project_properties_total_count = r.data;
                    }
                });

                return false;
            });
        }

        getProjectRealestatesCount();
    }

    $('#load-more-project').on('click', function (e) {
        e.preventDefault();

        var _this = $(this);
        _this.addClass('active').find('span').show();

        var offset = $('.project-property-wrapper .property-card').length;
        var ajax_url = $('.project-property-wrapper').attr('data-path') + '?' + $('.project-properties form').serialize() + '&offset=' + offset;

        $.ajax({
            url: ajax_url,
            success: function (r, status, jqXHR) {
                var initial_scroll_position = $(window).scrollTop();

                $('.project-property-wrapper').append(r);
                settingCardImagesDimensions();

                if (project_properties_total_count !== false && $('.project-property-wrapper .property-card').length >= project_properties_total_count) {
                    $('#load-more-project').hide();
                }

                $(document).scrollTop(initial_scroll_position);

                _this.removeClass('active').find('span').hide();
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
                getProjectRealestatesCount();
            }
        });

        return false;
    });

    $('.project-properties form').on('submit', function (e) {
        e.preventDefault();
        $('.preloader-project-properties').show();

        var form_params = $('.project-properties form').serialize();
        $('.project-property-wrapper').html('');
        $('#load-more-project').removeClass('visible');

        $.ajax({
            url: $('.project-property-wrapper').attr('data-path') + '?' + form_params,
            success: function (r, status, jqXHR) {
                $('.preloader-project-properties').hide();
                $('.project-property-wrapper').html(r);

                getProjectRealestatesCount();
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });
    });

    searchInit();

    $('.card-gallery').slick({
        'prevArrow': '<span class="slick-prev slick-nav"><i class="fas fa-angle-left"></i></span>',
        'nextArrow': '<span class="slick-next slick-nav"><i class="fas fa-angle-right"></i></span>',
    });

    import('./gallery').then(({
        default: Gallery
    }) => {
        const gallery = new Gallery('<span class="slick-prev slick-nav"><i class="fas fa-angle-left"></i></span>', '<span class="slick-next slick-nav"><i class="fas fa-angle-right"></i></span>');
        if ($('.lightbox-gallery').length) {
            gallery.init();
        }

        $(document).on('click', '#ground-plans-tab', function (e) {
            $('.preloader').show();
            $('.detail-gallery-wrapper').removeClass('visible');

            gallery.reInit('.ground-plans-gallery');

            let _gallery_height = $('.ground-plans-gallery .slick-track').height();

            setTimeout(function () {
                if (_gallery_height == 0) {
                    $('#ground-plans-tab').trigger('click');
                } else {
                    $('.preloader').hide();
                    $('.detail-gallery-wrapper').addClass('visible');
                }
            }, 100);
        });

        $('.lightbox-gallery').each(function (i, el) {
            let pswpElement = $(document).find('.pswp');
            let galleryItems = $(this).children().find('.gallery-item');

            $(galleryItems).each(function (idx, inEl) {
                $(inEl).on('click', function () {
                    let startIndex = $(this).data('item-index');
                    gallery.lightBox(galleryItems, pswpElement, startIndex);
                });
            });
        });
    });

    if ($('body#property').length > 0 || $('body#project').length > 0) {
        imagesLoaded('.detail-gallery', function () {
            // images have loaded
            $('.preloader').hide();
            $('.detail-gallery-wrapper').addClass('visible');
        });
    }

    $(document).on('click', '.ground-plans-trigger', function (e) {
        e.preventDefault();
        $('.ground-plans-wrapper').find('.gallery-item:first-child').trigger('click');
    });

    $(document).on('click', '.scroll-to-contact', function (e) {
        e.preventDefault();

        $([document.documentElement, document.body]).animate({
            scrollTop: $('.property-section .contact-form').offset().top - $('.header-container').height() - 50
        }, 500);
    });

    let search_type = 'search_group';
    if ($(window).width() < 1024) {
        search_type = 'search_mobile';
    }

    //// NAKNADNO UČITAVANJE I PRIKAZIVANJE TRAŽILICE
    // NASLOVNICA
    if ($('#homepage .main-header').length > 0) {
        if (search_type == 'search_group') {
            search_type = 'search_home';
        }

        $.ajax({
            url: '/form/reload?search_type=' + search_type + '&locale=' + $('html').attr('lang'),
            success: function (r, status, jqXHR) {
                if ($(window).width() < 1024) {
                    $('<div class="group-search"><div class="container">' + r.data + '</div></div>').insertAfter('#homepage .search-wrapper');
                    searchInit();
                } else {
                    $('#homepage .main-header').append(r.data).fadeIn();
                    searchInit();
                }

                // Homepage search: don't show action selector if only one action is available
                if ($('.homepage-search .action-item-wrapper .action-type ul').length < 2) {
                    $('.homepage-search .action-type').parent().remove();
                }
            }
        });
    }

    // COUNT NEKRETNINA
    function getRealestateCount() {
        var form_params = $('.group-search form').serialize();

        $.ajax({
            url: propertyCountRoute + '?' + form_params,
            success: function (r, status, jqXHR) {
                if ($('.property-wrapper .property-card').length < r.data) {
                    $('#load-more').addClass('visible');
                } else {
                    $('#load-more').removeClass('visible');
                }
            }
        });

        return false;
    }

    // STRANICA S PRIKAZOM TRAZILICE SKUPNOG PRIKAZA
    if ($('#property-group-view .group-search').length > 0 ||
        $('#property-show-map-view .group-search').length > 0 ||
        $('#user-area-index .group-search').length > 0 ||
        $('#favorites-group-view .group-search').length > 0 ||
        $('#stored-search-view .group-search').length > 0 ||
        $('#generated-links-group-view .group-search').length > 0
    ) {
        var selector = '#' + $('body').attr('id') + ' .group-search .container';
        if ($('body').hasClass('fluid-layout')) {
            selector = selector + '-fluid';
        }

        $.ajax({
            url: '/form/reload?' + $('.detail-search form').serialize() + '&search_type=' + search_type,
            beforeSend: function (r) {
                $(selector).hide();
            },
            success: function (r, status, jqXHR) {
                $(selector).html(r.data).fadeIn();
                searchInit();

                // Group search: don't show action selector if only one action is available
                $('.group-search .no-select').parent().remove();

                // NALAZIMO SE NA SKUPNOJ STRANICI NEKRETNINA
                if (document.getElementById('property-group-view')) {
                    getRealestateCount();
                }
            }
        });
    }

    // NACRTAJ NA KARTI
    if (($('#property-map-view .group-search').length > 0)) {
        var form_params = $('.detail-search form').serialize();
        var bodyId = $('body').attr('id');
        $.ajax({
            url: '/form/reload?' + form_params + '&search_type=search_map',
            beforeSend: function (r) {
                $('#' + bodyId + ' .group-search .container').hide();
            },
            success: function (r, status, jqXHR) {
                $('#' + bodyId + ' .group-search .container').html(r.data).fadeIn();
                searchInit();
            }
        });
    }

    $(document).delegate('.show-detail-search', 'click', function (e) {
        if ($('body').hasClass('homepage') && $(window).width() >= 1024) {
            var form_params = $('.homepage-search form').serialize();
        } else {
            var form_params = $('.group-search form').serialize();
        }

        // Detail search: don't show action selector if only one is available
        $('.detail-search .no-select').parent().css('display', 'none');

        $.ajax({
            url: propertyCountRoute + '?' + form_params,
            beforeSend: function () {
                $('.modal-footer .result-count > i').show();
                $('.modal-footer .result-count > span').html('');
            },
            success: function (r, status, jqXHR) {
                $('.modal-footer .result-count > i').hide();
                $('.modal-footer .result-count > span').html(r.data);
            }
        });
    });

    $('#detail-search form').on('change', function (e) {
        var form_params = $('#detail-search form').serialize();

        $.ajax({
            url: propertyCountRoute + '?' + form_params,
            beforeSend: function () {
                $('.modal-footer .result-count > i').show();
                $('.modal-footer .result-count > span').html('');
            },
            success: function (r, status, jqXHR) {
                $('.modal-footer .result-count > i').hide();
                $('.modal-footer .result-count > span').html(r.data);
            }
        });
    });

    function getProjectsCount() {
        $.ajax({
            url: projectCountRoute,
            success: function (r, status, jqXHR) {
                if ($('.project-wrapper .property-card').length < r.data) {
                    $('#load-more').addClass('visible');
                } else {
                    $('#load-more').removeClass('visible');
                }
            }
        });

        return false;
    }

    function getProjectRealestatesCount() {
        var form_params = $('.project-properties form').serialize();

        $.ajax({
            url: propertyCountRoute + '?' + form_params,
            success: function (r, status, jqXHR) {
                if ($('.project-property-wrapper .property-card').length < r.data) {
                    $('#load-more-project').addClass('visible');
                } else {
                    $('#load-more-project').removeClass('visible');
                }
            }
        });

        return false;
    }

    var realestate_total_count = false;
    if (realestate_total_count == false) {
        if (document.getElementById('property-group-view') !== null) {
            $('#load-more').on('click', function (e) {
                e.preventDefault();

                var form_params = $('.group-search form').serialize();

                $.ajax({
                    url: propertyCountRoute + '?' + form_params,
                    success: function (r, status, jqXHR) {
                        realestate_total_count = r.data;
                    }
                });

                return false;
            });
        }
    }

    var project_total_count = false;
    if (project_total_count == false) {
        if (document.getElementById('project-group-view') !== null) {
            $('#load-more').on('click', function (e) {
                e.preventDefault();
                $.ajax({
                    url: '/project/count/',
                    success: function (r, status, jqXHR) {
                        project_total_count = r.data;
                    }
                });

                return false;
            });
        }
    }

    $('#property-group-view .sort-by-select').on('change', function (e) {
        e.preventDefault();

        var form_params = $('.group-search form').serialize();
        var order_by = $(this).val();

        var _this = $(this);
        _this.addClass('active').find('span').show();

        $.ajax({
            url: $('.group-property-section').attr('data-path') + '/?' + form_params + '&order=' + order_by,
            success: function (r, status, jqXHR) {
                var initial_scroll_position = $(window).scrollTop();

                $('.property-wrapper').html(r);

                if (realestate_total_count !== false && $('.property-wrapper .property-card').length >= realestate_total_count) {
                    $('#load-more').hide();
                }

                $(document).scrollTop(initial_scroll_position);
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });

        return false;
    });

    $('#project-group-view .sort-by-select').on('change', function (e) {
        e.preventDefault();

        var order_by = $(this).val();

        var _this = $(this);
        _this.addClass('active').find('span').show();

        $.ajax({
            url: '/project/ajax/html/' + pageLocale + '?order=' + order_by,
            success: function (r, status, jqXHR) {
                var initial_scroll_position = $(window).scrollTop();

                $('.project-wrapper').html(r);

                if (project_total_count !== false && $('.project-wrapper .property-card').length >= project_total_count) {
                    $('#load-more').hide();
                }

                $(document).scrollTop(initial_scroll_position);
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });

        return false;
    });

    $('#load-more').on('click', function (e) {
        e.preventDefault();

        var _this = $(this);
        _this.addClass('active').find('span').show();

        if (document.getElementById('project-group-view') !== null) {
            var offset = $('.project-wrapper .property-card').length;
            var ajax_url = '/project/ajax/html/' + pageLocale + '?offset=' + offset + '&order=' + $('.sort-by-select').val();
        } else {
            var offset = $('.property-wrapper .property-card').length;
            var ajax_url = $('.group-property-section').attr('data-path') + '?' + $('.group-search form').serialize() + '&offset=' + offset + '&order=' + $('.sort-by-select').val();
        }

        $.ajax({
            url: ajax_url,
            success: function (r, status, jqXHR) {
                var initial_scroll_position = $(window).scrollTop();

                if (document.getElementById('project-group-view') !== null) {
                    $('.project-wrapper').append(r);
                    if (project_total_count !== false && $('.project-wrapper .property-card').length >= project_total_count) {
                        $('#load-more').hide();
                    }
                } else {
                    $('.property-wrapper').append(r);
                    settingCardImagesDimensions();

                    if (realestate_total_count !== false && $('.property-wrapper .property-card').length >= realestate_total_count) {
                        $('#load-more').hide();
                    }
                }

                $(document).scrollTop(initial_scroll_position);

                _this.removeClass('active').find('span').hide();
                setTimeout(initializeCarousel('.property-card .card-gallery'), 1000);
            }
        });

        return false;
    });

    function initializeCarousel(element) {
        $(element + ':not(.slick-initialized)').slick({
            'prevArrow': '<span class="slick-prev slick-nav"><i class="fas fa-angle-left"></i></span>',
            'nextArrow': '<span class="slick-next slick-nav"><i class="fas fa-angle-right"></i></span>',
        });
    }

    // REGISTRATION SUBMISSION
    $(document).delegate('#user-registration', 'submit', function (e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.

        var form = $(this);
        var url = form.attr('action');
        $('#user-registration .help-block').remove();
        // let html = `<span class="help-block"><ul class="list-unstyled"><li><span class="fa fa-exclamation-triangle"></span> U bazi web stranice već postoji korisnik s istom e-mail adresom. Pokušajte se registrirati s drugom e-mail adresom ili zatražite povrat lozinke.</li></ul></span>`;

        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: function (data) {
                try {
                    if ((data.data.message === 'OK') || (data.data.message !== undefined)) {
                        var _html = $('.success-message-holder').html();
                        $('.wrapper_1 .booking-calendar-title').after(_html);
                        $('#web_user').hide();
                        $('p.required').hide();
                        $('.btn-success').hide();
                        $('.success-message-holder').removeClass('d-none');
                        /*var _html2 = $('.success-message-holder-settings').html();
                        $('.section user-settings-module').append(_html2);
                        $('#user-registration .form-group:last-of-type').prepend(_html2);*/
                    }
                } catch {
                    $('.wrapper_1').html(data);
                }
            },
            error: function (error) {
                var _html = $('.error-message-holder').html();
                $('#user-registration .form-group:last-of-type').prepend(_html);
            }
        });
    });
});

// STORING SEARCH QUERY
$('#property-group-view #form-storing-search').submit(function (e) {
    e.preventDefault();

    var form = $(this);

    form.siblings('.alert').fadeOut();
    form.find('.modal-save-btn').attr('disabled', 'disabled').find('i').addClass('fa-spin');
    // $('#saving-search-modal').modal('hide');

    $.ajax({
        type: 'POST',
        url: '/user-area/stored-search/ajax/add' + window.location.search,
        data: form.serialize(),
        success: function () {
            form.find('.modal-save-btn').removeAttr('disabled').find('i').removeClass('fa-spin');
            form.fadeOut().siblings('.alert-success').removeClass('d-none').fadeIn().find('strong').html(form.find('input').val());
        },
        error: function (data) {
            form.find('.modal-save-btn').removeAttr('disabled').find('i').removeClass('fa-spin');
            form.siblings('.alert-danger').removeClass('d-none').fadeIn();
        }
    });
});

// Search-modal after closing it. Setting empty content
$('#saving-search-modal').on('hidden.bs.modal', function (e) {
    $(this)
        .find('form').fadeIn().end()
        .find('.alert').fadeOut().end()
        .find('input,textarea,select').val('').end()
        .find('input[type=checkbox], input[type=radio]').prop('checked', '').end();
});

$('#stored-search-view .erase-stored-search').on('click', function (e) {
    e.preventDefault();

    const id = $(this).attr("data-id");

    $.ajax({
        type: 'POST',
        url: '/user-area/stored-search/ajax/delete/' + id,
        success: function (data) {
            $('#stored-' + id).hide('slow').remove();
        }
    });
});

$('#stored-search-view .stored-search-notification').on('click', function (e) {
    e.preventDefault();

    const path = $(this).attr('data-path');
    let name = $(this).attr('data-name');
    const numOfCharacters = 50;

    if (name.length > numOfCharacters) {
        name = name.substring(0, numOfCharacters) + '...';
    }

    $.ajax({
        type: 'POST',
        url: path,
        success: function (data) {
            $('body').prepend(data);
            $('#notification-modal-header').append(name);
            $('#notification-modal').modal();
        }
    });
});

$(document).delegate('.stored-search-notification-frequency', 'submit', function (e) {
    e.preventDefault();

    const path = $('.stored-search-notification').attr('data-path');
    const form = $(this);

    var value = form.find('option:selected').text();

    form.siblings('.alert').fadeOut();
    form.find('.save-frequency-btn').attr('disabled', 'disabled').find('i').addClass('fa-spin');

    $.ajax({
        type: 'POST',
        url: path,
        data: form.serialize(),
        success: function () {
            $('.alert-success .frequency').html(value);

            form.find('.save-frequency-btn').removeAttr('disabled').find('i').removeClass('fa-spin');
            form.fadeOut().siblings('.alert-success').removeClass('d-none').fadeIn().find('strong').html(form.find('input').val());
        },
        error: function (data) {
            form.find('.save-frequency-btn').removeAttr('disabled').find('i').removeClass('fa-spin');
            form.siblings('.alert-danger').removeClass('d-none').fadeIn();
        }
    });
});

// Notification frequency modal after closing it. Setting empty content again
$(document).on('hidden.bs.modal', '#notification-modal', function (e) {
    $(this)
        .find("input,textarea,select")
        .val('')
        .end()
        .find("input[type=checkbox], input[type=radio]")
        .prop("checked", "")
        .end();

    $('#notification-modal-header').empty();
});

// closing notification message alert
$('.notification-modal-message .message-close-btn').on('click', function (e) {
    $('.notification-modal-message .text').empty();
    $('.notification-modal-message').addClass('d-none');
});


// Detail search - SubType filtration based on selected Type and location filtration
var detailChoice = $('#detail-search form select#type');
var clickTimeout;

detailChoice.change(function () {
    clearTimeout(clickTimeout);

    var child = $(this).attr('data-dependable-child');
    var form = $(this).closest('form');
    var parent = $(this).attr('id');
    var ajaxUrl = propertySubTypeOptionsRoute;
    var data = {};

    data['locale'] = $('html').attr('lang');
    data[$(this).attr('id')] = $(this).val();

    /* TODO: ZA KAJ JE OVO START
    ajaxUrl = form.attr('action') + data['locale'] + '/search-options';
    data['child'] = $(this).attr('data-dependable-child');
    data['parent'] = $(this).attr('id');
    data['value'] = $(this).val();
    /* ZA KAJ JE OVO END */

    if ($(this).val().length > 0) {
        clickTimeout = setTimeout(function () {
            $('#detail-search select#' + child)[0].sumo.unload();
            $('#detail-search select#' + parent)[0].sumo.disable();
            $('#detail-search #' + parent).css('display', 'none');
            $('#detail-search #' + parent + ' .optWrapper ul').css('display', 'none');
            $('.disabled .SelectBox label i').css('display', 'none');
            $('#detail-search #' + parent).parent().append('<div class="preloader spinner text-center fa-2x" style="font-size:26px">' +
                '<i class="fas fa-spinner fa-pulse position-absolute" style="top:6px; right:8px;"></i></div>');

            $.ajax({
                url: ajaxUrl,
                type: form.attr('method'),
                data: data,
                success: function (response) {
                    $('#' + child).html(response);

                    $('.disabled .SelectBox label i').css('display', 'block');
                    $('#detail-search select#' + child).SumoSelect(SumoSelectOptions);
                    $('#detail-search select#' + parent)[0].sumo.enable();
                    $('#detail-search #' + parent + ' .optWrapper ul').css('display', 'block');
                    $('#detail-search #' + parent).css('display', 'block');
                    $('#detail-search .preloader').remove();
                }
            });
        }, 700);
    }
});


// PropertySearchController -> searchOptions() ajax
var search = '';
var timeout;
$(document).ready(function () {
    if ($('body').attr('id') === 'homepage' && $(window).width() >= 1019) {
        search = '.homepage-search';
    } else {
        search = '.group-search'
    }

    $(document).on("change", search + ' .location-parameter', function () {
        clearTimeout(timeout);
        var form = $(this).closest('form');
        var data = {};

        data['value'] = $(this).val();
        data['parent'] = $(this).attr('id');
        data['child'] = $(this).attr('data-dependable-child');
        data['locale'] = $('html').attr('lang');

        if ((data['child'].length > 0) && (data['parent'].length > 0) && (data['value'].length > 0)) {
            timeout = setTimeout(function () {
                $(search + ' .optWrapper ul').css('display', 'none');
                $(search + ' #' + data['parent']).parent().addClass('parent');
                $(search + ' .parent .SelectBox label i').css('display', 'none');
                $(search + ' #' + data['parent']).parent().append('<div class="preloader spinner text-center fa-2x" style="font-size:26px">' +
                    '<i class="fas fa-spinner fa-pulse position-absolute" style="top:11px; right:8px;"></i></div>');

                $.ajax({
                    url: form.attr('action') + data['locale'] + '/search-options',
                    type: form.attr('method'),
                    data: data,
                    success: function (response) {
                        $(search + ' #' + data['child']).html(response);

                        $(search + ' select#' + data['child'])[0].sumo.unload();

                        if ($(search + ' #' + data['child'] + ' option').length > 1) {
                            $(search + ' #' + data['child'] + ' .no-locations').remove();
                        }

                        $(search + ' select#' + data['child']).SumoSelect(SumoSelectOptions);
                        $(search + ' .optWrapper ul').css('display', 'block');
                        $(search + ' .preloader').remove();
                        $(search + '  .parent .SelectBox label i').css('display', 'block');
                        $(search + ' #' + data['parent']).parent().removeAttr('parent');
                    }
                });

            }, 700);
        }
    });
});